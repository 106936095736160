.portfoliopage-main {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    148deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(98, 130, 234) 78%
  );
  height: auto;
  margin-bottom: 100px;
}
.portfoliopage {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.portfoliopage .image-content {
  justify-content: center;
  align-items: center;
}
.portfoliopage .portfoliopage-title {
  font-size: 50px;
  font-weight: 700;
  color: var(--black);
}
.portfoliopage .portfoliopage-para {
  font-size: 16px;
  color: var(--black);
}

.portfoliopage .image-card1 img {
  border-radius: 0 50% 0 0;
  object-fit: cover;
}

.portfoliopage .image-card2 img {
  border-radius: 50% 50% 0 0;
  object-fit: cover;
}

.portfoliopage .image-card3 img {
  border-radius: 50% 0 0 0;
  object-fit: cover;
}

/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfoliopage .text-content {
    width: 90%;
  }
  .portfoliopage .image-content img {
    width: 280px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfoliopage .portfoliopage-title {
    font-size: 40px;
  }
  .portfoliopage .image-content img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .portfoliopage .portfoliopage-title {
    font-size: 30px;
  }
  .portfoliopage .image-content img {
    width: 150px;
  }
}

@media only screen and (max-width: 479px) {
  .portfoliopage .image-content img {
    width: 100px;
  }
}
@media only screen and (max-width: 390px) {
  .portfoliopage .image-content img {
    width: 80px;
  }
}
@media only screen and (max-width: 375px) {
  .portfoliopage .image-content img {
    width: 80px;
  }
}
@media only screen and (max-width: 320px) {
  .portfoliopage .image-content img {
    width: 60px;
  }
}
