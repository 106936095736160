.company-main {
  background-color: var(--secondary-blue);
}

.head-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.director-profile .director-profile-title {
  font-size: 50px;
  font-weight: 700;
  color: var(--black);
}
.director-profile .head-img img {
  width: 450px;
  height: 450px;
  object-fit: cover;
  border-radius: 20px;
}
.head-content .head-name {
  font-size: 40px;
  font-weight: 700;
  color: var(--primary-blue);
}
.head-content .head-designation {
  font-size: 20px;
  font-weight: 600;
  color: var(--black);
}

.head-content .head-intro {
  font-size: 16px;
  color: var(--black);
}

/* member-content */
.member-content .member-img img {
  width: 295px;
  height: 350px;
  object-fit: cover;
  border-radius: 20px;
}
.member-content .member-name {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-blue);
}
.member-content .member-designation {
  font-size: 16px;
  color: #787878;
  margin-top: -10px;
}

/* Responsive */

@media only screen and (max-width: 1200px) {
  .member-content .member-img img {
    width: 250px;
    height: 300px;
  }
  .member-content .member-name {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .director-profile .head-img img {
    width: 350px;
    height: 400px;
  }
  .member-content .member-img img {
    width: 200px;
    height: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .head-content {
    justify-content: space-between;
  }
  .director-profile .director-profile-title {
    font-size: 40px;
  }

  .director-profile .head-img img {
    width: 300px;
    height: 350px;
  }
  .head-content .head-name {
    font-size: 30px;
  }
  .head-content .head-designation {
    font-size: 18px;
  }
  .head-content .head-intro {
    font-size: 14px;
  }
  .member-content .member-img img {
    width: 200px;
    height: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .director-profile .head-img img {
    width: 100%;
    height: 350px;
  }
  .head-text {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 479px) {
}
@media only screen and (max-width: 425px) {
}
@media only screen and (max-width: 390px) {
}
@media only screen and (max-width: 375px) {
}
@media only screen and (max-width: 320px) {
}
