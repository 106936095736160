.empoweragri .empoweragri-content {
  margin-top: 100px;
}

.empoweragri-left > video {
  object-fit: cover;
  height: 500px;
  border-radius: 0 150px 0 0;
}

.empoweragri-right {
  text-align: left;
}

.empoweragri-right .empower-title {
  font-size: 50px;
  font-weight: 700;
  color: var(--black);
}
.empoweragri-right .empower-para {
  font-size: 16px;
  color: var(--black);
}

.empoweragri-right .empower-cta {
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  margin-top: -30px;
  color: var(--primary-blue);
  width: 200px;
}

.empoweragri_experience-title {
  color: var(--primary-blue);
}

/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .empoweragri-right .empower-title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .empoweragri-left > video {
    height: 450px;
  }

  .empoweragri-right .empower-title {
    font-size: 30px;
  }
  .empoweragri-right .empower-para {
    font-size: 14px;
    margin-top: -30px;
  }
  .empoweragri-right .empower-cta {
    margin-top: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .empoweragri-left > video {
    height: 400px;
    margin-bottom: 40px;
  }
  .empoweragri-right .empower-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .empoweragri-right .empower-title {
    font-size: 30px;
  }
}
