.partnership-section {
  height: 400px;
  background-color: var(--secondary-green);
  border-radius: 20px;
  margin-top: 100px;
}

.partnership-section .partnership-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.partnership-right {
  text-align: left;
}

.partnership-right .partnership-title {
  font-size: 50px;
  font-weight: 700;
  color: var(--primary-green);
}
.partnership-right .partnership-para {
  font-size: 16px;
  color: var(--black);
}

/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .partnership-right .partnership-title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .partnership-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }
  .partnership-left {
    display: none;
  }

  .partnership-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  .partnership-right .partnership-title {
    font-size: 30px;
  }
  .partnership-right .partnership-para {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .partnership-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
  }
  .partnership-left {
    display: none;
  }
  .partnership-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .partnership-right .partnership-title {
    font-size: 25px;
  }
  .partnership-right .partnership-para {
    font-size: 14px;
  }
}

@media only screen and (max-width: 479px) {
  .partnership-right .partnership-title {
    font-size: 25px;
  }
}

@media only screen and (max-width: 425px) {
  .partnership-section {
    height: 350px;
  }
}
