/* management-content */
.management-profile-title {
  font-size: 50px;
  font-weight: 700;
  color: var(--black);
}

.management-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.management-content .management-img img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 20px;
}
.management-content .management-name {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-blue);
}
.management-content .management-designation {
  font-size: 16px;
  color: #787878;
  margin-top: -10px;
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .management-content .management-img img {
    height: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .management-content .management-img img {
    height: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .management-content .management-img img {
    width: 80%;
    height: 400px;
  }
  .management-profile-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 479px) {
}
@media only screen and (max-width: 425px) {
}
@media only screen and (max-width: 390px) {
}
@media only screen and (max-width: 375px) {
}
@media only screen and (max-width: 320px) {
}
