.portfolio-section .portfolio-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.portfolio-top {
  flex-direction: column;
  row-gap: 40px;
  justify-content: center;
  align-items: center;
}
.portfolio-top .portfolio-title {
  font-size: 50px;
  font-weight: 700;
  color: var(--primary-blue);
  width: 800px;
}
.portfolio-top .portfolio-para {
  font-size: 16px;
  color: var(--black);
  width: 600px;
}
.portfolio-bottom {
  justify-content: center;
  align-items: center;
  row-gap: 40px;
}
.portfolio-bottom .portfolio-video video {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: 0;
}

/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-top .portfolio-title {
    font-size: 40px;
    width: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-top .portfolio-title {
    font-size: 40px;
    width: 550px;
  }
  .portfolio-top .portfolio-para {
    width: 550px;
  }
}

@media only screen and (max-width: 500px) {
  .portfolio-top .portfolio-title {
    font-size: 40px;
    width: 400px;
  }
  .portfolio-top .portfolio-para {
    width: 400px;
  }
}

@media only screen and (max-width: 479px) {
  .portfolio-top .portfolio-title {
    font-size: 30px;
    width: 300px;
  }
  .portfolio-top .portfolio-para {
    width: 300px;
  }
}
