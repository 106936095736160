.go-back {
  text-align: left;
}
.go-back button {
  padding: 10px 20px 10px 20px;
  border-style: none;
  border: 2px solid var(--primary-green);
  background-color: transparent;
  border-radius: 50px;
  color: var(--primary-green);
}
.go-back a {
  font-size: 20px;
  text-decoration: none;
  color: var(--primary-green);
}
.go-back button:hover {
  background-color: var(--primary-green);
  color: var(--white);
}

.blog-template .blog-template-title h1 {
  font-size: 50px;
  font-weight: 700;
  color: var(--black);
  text-align: left;
}
.blog-template .blog-template-para {
  font-size: 16px;
  color: var(--black);
  text-align: left;
}
.blog-template-img img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
