@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300&display=swap");

/* color variables */
:root {
  --primary-green: #17af4e;
  --primary-blue: #005da4;
  --secondary-blue: #e5f4ff;
  --secondary-green: #c8ffdc;
  --white: #ffffff;
  --black: #000000;
}

body {
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
}
