.blogpage-main {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    148deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(98, 234, 112, 1) 78%
  );
  height: auto;
  margin-bottom: 100px;
}
.blogpage {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.blogpage .image-content {
  justify-content: center;
  align-items: center;
}
.blogpage .blogpage-title {
  font-size: 50px;
  font-weight: 700;
  color: var(--black);
}
.blogpage .blogpage-para {
  font-size: 16px;
  color: var(--black);
}
.blogpage .image-content {
  width: 100%;
}

.blogpage .blog-image-card1 {
  width: 100%;
}
.blogpage .blog-image-card1 img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 20px;
}
/* blog card */
.blogpage-card .card-list a {
  text-decoration: none;
}

.blogpage-card .card-list .card {
  border: none;
  background-color: transparent;
  color: var(--black);
}
.blogpage-card .card-list .card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 40px;
}

/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blogpage .text-content {
    width: 90%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blogpage .servicepage-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .blogpage .blogpage-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .blogpage .blog-image-card1 img {
    height: 300px;
  }
}
@media only screen and (max-width: 390px) {
}
@media only screen and (max-width: 375px) {
}
@media only screen and (max-width: 320px) {
}
