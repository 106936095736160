.blog-section {
  margin-top: 100px;
}
.blog-content {
  padding-top: 40px;
  padding-bottom: 60px;
}
.blog-top-left {
  text-align: left;
}
.blog-top-left .blog-title {
  font-size: 50px;
  font-weight: 700;
  color: var(--primary-blue);
}
.blog-top-left .blog-para {
  font-size: 18px;
  color: var(--black);
}
.blog-top-right img {
  width: 200px;
  height: 200px;
}

/* .blog-card .card-list {
  padding: 10px;
  color: var(--black);
} */
.blog-card .card a {
  text-decoration: none;
  color: var(--white);
}
.blog-card .card {
  border: none;
  background-color: var(--primary-blue);

  width: 400px;
}

.blog-card .card img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 479px) {
}
@media only screen and (max-width: 390px) {
  .blog-card .card {
    width: 300px;
  }
}
