.aboutpage-main {
  height: auto;
  background-color: var(--primary-green);
}
.aboutpage {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.aboutpage .top-row {
  display: flex;
  justify-content: center;
}

.aboutpage .aboutpage-title {
  font-size: 50px;
  font-weight: 700;
  /* color: var(--white); */
}
.aboutpage .aboutpage-para {
  font-size: 16px;
  /* color: var(--white); */
}
.about-img1 {
  display: flex;
  justify-content: flex-end;
}
.about-img1 img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}
.about-img2 {
  display: flex;
  justify-content: flex-start;
}
.about-img2 img {
  width: 900px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

/* mission section */
.mission-section {
  /* background-color: var(--primary-blue); */
  background-color: aliceblue;
  margin-bottom: 2rem;
}
.mission-content {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 60px;
}

.mission-title {
  font-size: 50px;
  font-weight: 700;
  /* color: var(--white); */
}

.mission-para {
  font-size: 16px;
  width: 600px;
  /* color: var(--white); */
}

.mission-list .mission-list-text {
  width: 500px;
}

/* introduction section */
.intro-section {
  background-color: var(--secondary-blue);
  height: auto;
  margin-bottom: 100px;
}
.intro-content {
  padding-top: 60px;
  padding-bottom: 60px;
}

.intro-img img {
  align-items: flex-start;
}

.intro-content .intro-title {
  font-size: 40px;
  font-weight: 700;
  color: var(--primary-blue);
  text-align: left;
}
.intro-content .intro-para {
  font-size: 16px;
  color: var(--black);
  text-align: left;
  width: 500px;
}

/* Responsive */
@media only screen and (min-width: 1200px) {
  /* .about-img2 img {
    width: 700px;
  } */
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .aboutpage .aboutpage-title {
    font-size: 40px;
  }
  .about-img2 img {
    width: 500px;
  }
  .mission-list .mission-list-text {
    width: 400px;
  }
  .intro-content .intro-title {
    font-size: 30px;
  }
  .intro-content .intro-para {
    width: 400px;
  }
  .intro-img img {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aboutpage .aboutpage-title {
    font-size: 30px;
  }
  .about-img1 img {
    width: 300px;
    height: 300px;
  }
  .about-img2 img {
    width: 360px;
    height: 300px;
  }
  .mission-list .mission-list-text {
    width: 300px;
  }
  .mission-list .mission-list-text p {
    font-size: 14px;
  }
  .intro-content .intro-title {
    font-size: 25px;
  }
  .intro-content .intro-para {
    font-size: 14px;
  }
  .intro-content .intro-para {
    width: 300px;
  }
  .intro-img img {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .aboutpage .aboutpage-title {
    text-align: center;
    font-size: 30px;
  }
  .aboutpage .aboutpage-para {
    text-align: center;
  }
  .aboutpage .about-img1 {
    margin-top: 30px;
  }
  .about-img1 {
    justify-content: center;
  }
  .about-img2 {
    justify-content: center;
  }
  .about-img2 img {
    width: 400px;
  }
  .intro-content .intro-title {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .mission-para {
    width: 400px;
  }

  .mission-list .mission-list-text {
    width: 300px;
  }
  .intro-img img {
    width: 400px;
  }
  .intro-content .intro-para {
    width: 400px;
  }
}
@media only screen and (max-width: 425px) {
  .intro-img img {
    width: 350px;
  }
  .intro-content .intro-para {
    width: 350px;
  }
}
@media only screen and (max-width: 390px) {
  .aboutpage .aboutpage-title {
    font-size: 25px;
  }
  .aboutpage .aboutpage-para {
    width: 350px;
  }
  .about-img1 img {
    width: 350px;
    height: 350px;
  }
  .about-img2 img {
    width: 350px;
  }
  .mission-para {
    width: 350px;
  }
  .intro-img img {
    width: 300px;
  }
  .intro-content .intro-para {
    width: 350px;
  }
}
@media only screen and (max-width: 375px) {
  .intro-content .intro-para {
    width: 320px;
  }
}
@media only screen and (max-width: 320px) {
  .aboutpage .aboutpage-title {
    font-size: 20px;
  }
  .aboutpage .aboutpage-para {
    width: 300px;
  }
  .about-img1 img {
    width: 300px;
    height: 300px;
  }
  .about-img2 img {
    width: 300px;
  }
  .mission-title {
    font-size: 30px;
  }
  .mission-para {
    width: 300px;
  }
  .intro-img img {
    width: 100%;
  }

  .intro-content .intro-para {
    font-size: 12px;
    width: 280px;
  }
}
