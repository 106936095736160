.contact-content {
  position: relative;
}

.map-container {
  width: 100%;
  height: 600px;
  border-radius: 20px;
}
.map-container > iframe {
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-radius: 20px;
  border: 0;
}

.contact-info-content {
  position: absolute;
  bottom: -150px;
  box-shadow: 0px 0px 30px rgb(0, 0, 0);
}
.contact-info-content .message-side {
  text-align: left;
  height: auto;
  padding: 30px 20px 30px 20px;
  background-color: var(--white);
}

.message-side .message-cta button {
  display: flex;
  padding: 10px 20px 10px 20px;
  border: none;
  background-color: var(--primary-green);
  color: var(--white);
}
.contact-info-content .info-side {
  text-align: left;
  height: auto;
  padding: 30px 20px 30px 20px;
  background-color: var(--primary-blue);
  color: var(--white);
}

/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
  .contact-info-content {
    position: relative;
    bottom: 200px;
  }
}

@media only screen and (max-width: 479px) {
}
