.portfolio-container {
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 20px;
  height: 100vh;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  background-color: #f0f0f0;
  border: none;
  padding: 15px 40px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.tab.active {
  background-color: var(--primary-green);
  color: white;
}

.content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  cursor: pointer;
  max-width: 600px;
}

.card:hover {
  transform: translateY(-5px);
}

.card-media {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.video-container {
  position: relative;
  width: 100%;
  height: auto;
}

.video-placeholder {
  width: 100%;
  height: 250px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: blur(5px);
  transition: filter 0.3s ease;
}

.video-container:hover .video-placeholder {
  filter: none;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  color: red;
  border: 3px solid white;
  border-radius: 10%;
}

.play-button:hover {
  opacity: 1;
}

.play-icon {
  width: 60px;
  height: 60px;
  padding: 10px;
  fill: red;
}

.card-content {
  padding: 15px;
}

.card-title {
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: bold;
}

.card-description {
  margin: 0;
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .content {
    grid-template-columns: 1fr;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: hidden;
}

.modal-content {
  position: relative;
  max-width: 100vh;
  max-height: 100vh;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

.modal-media {
  width: 100%;
  height: auto;
}

.modal-close {
  position: absolute;
  top: 10px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  background: red;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: none;
  color: white;
  cursor: pointer;
}

body {
  transition: overflow 0.3s;
}
