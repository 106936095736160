.service-main {
  background-color: var(--primary-green);
}
.service-section {
  margin-top: 100px;
}
.service-content {
  padding-top: 40px;
  padding-bottom: 60px;
}
.content-top-left {
  text-align: left;
}
.content-top-left .service-title {
  font-size: 50px;
  font-weight: 700;
  color: var(--white);
}
.content-top-left .service-para {
  font-size: 18px;
  color: var(--white);
}
.content-top-right img {
  width: 200px;
  height: 200px;
}
.service-card .card-list {
  padding: 10px;
}

.service-card .card-list:hover {
  border: 2px solid #fff;
  border-radius: 10px;
}
.service-card p {
  font-size: 14px;
}

/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 479px) {
}
